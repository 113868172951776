import { Injectable } from '@angular/core';
import { Room, RoomConnectOptions, RoomOptions, Track, RemoteTrackPublication, createLocalAudioTrack, Participant, RoomEvent, RemoteTrack, RemoteParticipant, isLocalTrack, isAudioTrack, createAudioAnalyser, TrackEvent } from 'livekit-client';

@Injectable({
  providedIn: 'root',
})
export class LivekitService {
  private room: Room | null = null;

  async connectToRoom(url: string, token: string) {
    try {
        this.room = new Room({
            adaptiveStream: false, // Improves performance
        });

        await this.room.connect(url, token, {
            autoSubscribe: true, // Automatically subscribe to audio tracks
        } as RoomConnectOptions);

        console.log('✅ Connected to LiveKit room:', this.room.name);
        console.log('👥 Connected participants:', this.room.numParticipants);

        // ✅ Publish an audio track so others can hear
        const localAudioTrack = await createLocalAudioTrack();
        // await this.room.localParticipant.setMicrophoneEnabled(true);
        await this.room.localParticipant.publishTrack(localAudioTrack);
        console.log(`🎤 ${this.room.localParticipant.identity} published audio track`);

        // ✅ Handle new participants joining
        this.room.on(RoomEvent.ParticipantConnected, (participant) => {
            console.log(`👥 New participant connected: ${participant.identity}`);

            participant.trackPublications.forEach((publication) => {
                console.log(`📢 Track available: ${publication.kind}, subscribed: ${publication.isSubscribed}`);
            });
        });

        // ✅ Handle when a participant publishes a new track
        this.room.on(RoomEvent.TrackPublished, async (publication: RemoteTrackPublication, participant: RemoteParticipant) => {
            console.log(`🎤 Track published by ${participant.identity}: ${publication.kind}`);
        
            if (publication.kind === 'audio') {
                console.log(`⏳ Subscribing to ${participant.identity}'s audio track...`);
        
                publication.on(TrackEvent.Subscribed, (track: RemoteTrack) => {
                    console.log(`🎧 Subscribed to ${participant.identity}'s track`);
                    if (track.kind === 'audio') {
                        const audioElement = track.attach();
                        document.body.appendChild(audioElement);
                        console.log('🎵 Playing audio from:', participant.identity);
                    }
                });
        
                publication.on(TrackEvent.Unsubscribed, (track: RemoteTrack) => {
                    console.log(`🚫 Unsubscribed from ${participant.identity}`);
                    track.detach().forEach(el => el.remove());
                });
            }
        });

        this.room.on(RoomEvent.TranscriptionReceived, (data, participant) => {
            // const message = new TextDecoder().decode(data); // Decode binary data
            console.log(`📜 Transcription from ${participant?.identity}:`, data);
        
            // Update UI with the transcription
            // this.updateTranscriptionUI(participant.identity, message);
        });

        this.room.on(RoomEvent.DataReceived, (data, participant) => {
            // const message = new TextDecoder().decode(data); // Decode binary data
            console.log(`📜 DataReceived from ${participant?.identity}:`, data);
        
            // Update UI with the transcription
            // this.updateTranscriptionUI(participant.identity, message);
        });
        

        // ✅ Handle incoming audio tracks
        this.room.on(RoomEvent.TrackSubscribed, (track: Track, publication: RemoteTrackPublication, participant: Participant) => {
            console.log(`🎧 Track subscribed: ${track.kind} from ${participant.identity}`);

            if (track.kind === 'audio') {
                const audioElement = track.attach();
                document.body.appendChild(audioElement);
                console.log('🎵 Playing audio from:', participant.identity);
            }
        });

        this.room.remoteParticipants.forEach((participant) => {
            console.log(`👤 ${participant.identity} - Tracks:`, participant);
            participant.trackPublications.forEach((publication) => {
                console.log(`📢 Track available: ${publication.kind}, subscribed: ${publication.isSubscribed}`);
                if (publication.isSubscribed) {
                    const track = publication.track;
                    if (track && isAudioTrack(track)) {
                        const audioElement = track.attach();
                        document.body.appendChild(audioElement);
                        console.log('🎵 Playing audio from:', participant.identity);
                    }
                }
            })
        });

        console.log('👥 Connected participants:', this.room.numParticipants);
    } catch (error) {
        console.error('❌ Error connecting to LiveKit:', error);
    }
}


  disconnect() {
    this.room?.disconnect();
    this.room = null;
  }
}
