import { Component, OnInit } from '@angular/core';
import { LivekitService } from '../../services/chat/livekit.service';
import { SharedService } from '../../shared/services/shared.service';
import { environment } from '../../../environments/environment';
import { ChatService } from '../../services/chat/chat.service';
import { Room, RoomConnectOptions, RoomOptions, Track, RemoteTrackPublication, createLocalAudioTrack, Participant, RoomEvent, RemoteTrack, RemoteParticipant, isLocalTrack, isAudioTrack, createAudioAnalyser, TrackEvent } from 'livekit-client';

@Component({
  selector: 'app-livekit-voice',
  templateUrl: './voice-liv.component.html',
  standalone: true,
  styleUrls: ['./voice-liv.component.scss']
})
export class LivekitVoiceComponent implements OnInit {
  private livekitUrl = 'wss://main-6056lcja.livekit.cloud/'; // Change this to your LiveKit server URL
  private token = 'YOUR_ACCESS_TOKEN'; // Replace with a valid token from your LiveKit server
  private room: Room | null = null;

  constructor(
    private livekitService: LivekitService,
    private _sharedService: SharedService,
    private _chatService: ChatService
  ) {
    this._sharedService.loading.set(false);
  }

  ngOnInit() {
    this._chatService.getLivkitToken().subscribe((res: any) => {
      console.log('Livekit token:', res);
      this.token = res.token;
    });
  }

  joinCall() {



    this.connectToRoom(this.livekitUrl, this.token);

    // setTimeout(() => {
    //   this._chatService.startTranscription(this.token).subscribe((res: any) => {
    //     this.livekitService.connectToRoom(this.livekitUrl, this.token);
    //   });
    // }, 2000);

  }

  async connectToRoom(url: string, token: string) {
    try {
      this.room = new Room({
        adaptiveStream: false, // Improves performance
      });

      await this.room.connect(url, token, {
        autoSubscribe: true, // Automatically subscribe to audio tracks
      } as RoomConnectOptions);

      console.log('✅ Connected to LiveKit room:', this.room.name);
      console.log('👥 Connected participants:', this.room.numParticipants);

      // ✅ Publish an audio track so others can hear
      const localAudioTrack = await createLocalAudioTrack();
      // await this.room.localParticipant.setMicrophoneEnabled(true);
      await this.room.localParticipant.publishTrack(localAudioTrack);
      console.log(`🎤 ${this.room.localParticipant.identity} published audio track`);

      // ✅ Handle new participants joining
      this.room.on(RoomEvent.ParticipantConnected, (participant) => {
        console.log(`👥 New participant connected: ${participant.identity}`);

        participant.trackPublications.forEach((publication) => {
          console.log(`📢 Track available: ${publication.kind}, subscribed: ${publication.isSubscribed}`);
        });
      });

      this._chatService.startTranscription(this.token).subscribe((res: any) => {
        console.log('Transcription started:', res);
      });

      // ✅ Handle when a participant publishes a new track
      this.room.on(RoomEvent.TrackPublished, async (publication: RemoteTrackPublication, participant: RemoteParticipant) => {
        console.log(`🎤 Track published by ${participant.identity}: ${publication.kind}`);

        if (publication.kind === 'audio') {
          console.log(`⏳ Subscribing to ${participant.identity}'s audio track...`);

          publication.on(TrackEvent.Subscribed, (track: RemoteTrack) => {
            console.log(`🎧 Subscribed to ${participant.identity}'s track`);
            if (track.kind === 'audio') {
              const audioElement = track.attach();
              document.body.appendChild(audioElement);
              console.log('🎵 Playing audio from:', participant.identity);
            }
          });

          publication.on(TrackEvent.Unsubscribed, (track: RemoteTrack) => {
            console.log(`🚫 Unsubscribed from ${participant.identity}`);
            track.detach().forEach(el => el.remove());
          });
        }
      });

      this.room.on(RoomEvent.DataReceived, (data, participant) => {
        console.log(`📜 Received transcription data from ${participant?.identity}:`, data);

        try {
          const message = new TextDecoder().decode(data);
          console.log(`📝 Decoded transcription message: ${message}`);
        } catch (error) {
          console.error('❌ Error decoding transcription data:', error);
        }
      });
      this.room.on(RoomEvent.TranscriptionReceived, (data, participant) => {
        console.log(`📜 Received transcription data from ${participant?.identity}:`, data);

      });


      // ✅ Handle incoming audio tracks
      this.room.on(RoomEvent.TrackSubscribed, (track: Track, publication: RemoteTrackPublication, participant: Participant) => {
        console.log(`🎧 Track subscribed: ${track.kind} from ${participant.identity}`);

        if (track.kind === 'audio') {
          const audioElement = track.attach();
          document.body.appendChild(audioElement);
          console.log('🎵 Playing audio from:', participant.identity);
        }
      });

      this.room.remoteParticipants.forEach((participant) => {
        console.log(`👤 ${participant.identity} - Tracks:`, participant);
        participant.trackPublications.forEach((publication) => {
          console.log(`📢 Track available: ${publication.kind}, subscribed: ${publication.isSubscribed}`);
          if (publication.isSubscribed) {
            const track = publication.track;
            if (track && isAudioTrack(track)) {
              const audioElement = track.attach();
              document.body.appendChild(audioElement);
              console.log('🎵 Playing audio from:', participant.identity);
            }
          }
        })
      });

      console.log('👥 Connected participants:', this.room.numParticipants);
    } catch (error) {
      console.error('❌ Error connecting to LiveKit:', error);
    }
  }

  leaveCall() {
    // this.livekitService.disconnect();
    this.room?.disconnect();
    this.room = null;
  }
}
